import {
    createCustomer,
    getBookingFormStatus,
    getFinalizeButton,
    getInputsThatNeedRefreshing,
    getSummaryLoader,
    refreshInputs,
    setBookingHours,
} from "./modules/booking/utils.js";
import { setupServiceCards, refreshServiceCardPrices } from "./modules/booking/service-card.js";
import { refreshPaypalButton } from "./modules/booking/paypal.js";
import {
    refreshLocalStateWithFreshSummary,
    refreshSummary,
    refreshSummaryRequest,
    setupSummaryWindow,
} from "./modules/booking/summary.js";
import { calculateRangeSlider } from "./modules/booking/range-slider.js";
import { setupTimeSelector } from "./modules/booking/time-selector.js";
import { setupBundlePopup } from "./modules/bundles.js";
import {
    getCurrentStep,
    setCurrentStep,
    setupConditionalRendering,
    render,
} from "./modules/booking/conditional-rendering.js";
import { setupMultipartForm } from "./modules/booking/multi-part-form.js";
import { handleValidations } from "./modules/booking/validation.js";

(function ($) {
    const debounceTimers = {};

    function debounce(func, timeout = 300, timerId = null) {
        if (timerId == null) timerId = func;
        let timer = debounceTimers[timerId];
        clearTimeout(timer);
        timer = setTimeout(() => func(), timeout);
        debounceTimers[timerId] = timer;
    }

    if (window.waitingForFacebookLead) {
        return;
    }
    var hash = window.location.hash;
    var isMobile = $(window).width() <= 480;

    $("#recaptcha-form").submit(function (e) {
        e.preventDefault();
        var $form = $(this);
        var formData = {};

        $form.serializeArray().map(function (item) {
            if (formData[item.name]) {
                if (typeof formData[item.name] === "string") {
                    formData[item.name] = [formData[item.name]];
                }
                formData[item.name].push(item.value);
            } else {
                formData[item.name] = item.value;
            }
        });

        $.ajax({
            type: "post",
            url: recaptchaUrl,
            dataType: "json",
            data: formData,
        })
            .done(function () {
                location.reload();
            })
            .fail(function (xhr, status, error) {});
    });

    let nonQuoteStep = getCurrentStep();

    // BOOKING PAGE RELATED NECESSARY STUFF START
    $(document).on("change", "input[name='multiplier']", function () {
        const inputVal = $(this).val();

        if (inputVal === "moving_out") {
            onMovingOutSelected();
        }
    });
    $(document).on("change", "input[name='moving-out_option']", function (e) {
        const inputVal = $(this).attr("id");

        if (inputVal === "moving-out_option-moving_out_with_quote") {
            nonQuoteStep = getCurrentStep();
            setCurrentStep(4);
            onMovingOutDeSelected();
            render();
        } else {
            setCurrentStep(nonQuoteStep);
            onMovingOutSelected();
        }
    });

    refreshPaypalButton();

    // Show inline error for not ticking the terms and conditions checkbox when submitting step2 form
    // $(document).on("submit", "#payment_details_form", async function (e) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //
    //     const isValid = validateStep2();
    //     if (!isValid) {
    //         console.error("step2 form not valid");
    //         return;
    //     }
    //     this.submit();
    // });

    function validateStep2(event) {
        console.log("validateStep2");

        var validated = true;
        var $firstErrorElement = "";

        $("p.inputfield-error").remove();

        var $field = $(".booking__payment > form").find('input[name="pp_allowed"]');

        if (!$field) return validated;

        if ($field.attr("type") === "checkbox" && !$field.is(":checked")) {
            $field.addClass("inputfield-error");
            $(
                '<p class="inputfield-error">Please accept the terms and conditions!</p>',
            ).insertAfter($('label[for="' + $field.attr("name") + '"'));
            validated = false;
        }

        if (validated === false && $firstErrorElement.length < 1) {
            $firstErrorElement = $field.closest("div");
        }

        if (validated === false) {
            scrollToElement($firstErrorElement, -50);
        }

        if (!validated && event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }

        return validated;
    }

    window.validateStep2 = validateStep2;

    function SliderButtonTextTranslations() {
        var sliderInterval = setInterval(function () {
            var slider = $(".slick-slider");

            if (slider && slider.hasClass("slick-initialized")) {
                var prevBtnText = $(".slider-prev-btn-text");
                var nextBtnText = $(".slider-next-btn-text");
                var nextBtn = $(".slick-next");
                var prevBtn = $(".slick-prev");

                nextBtn.text(nextBtnText.text());
                prevBtn.text(prevBtnText.text());

                var prevPos = nextBtn.outerWidth() + 40;

                prevBtn.css("right", prevPos + "px");

                clearInterval(sliderInterval);
            }
        }, 100);
    }

    SliderButtonTextTranslations();

    $("form").on("input", "input", function () {
        var $this = $(this);

        $this.removeClass("inputfield-error").removeClass("form-error");
        $this.nextAll("p.inputfield-error, .error-message").slideUp(300, function () {
            $(this).remove();
        });
    });

    if (getBookingFormStatus() === "step1") {
        refreshAllInputs();
    } else {
        getSummaryLoader().fadeOut(150);
        getFinalizeButton().prop("disabled", false);
    }

    var isLandingPage = $(".booking__wrap--fullwidth").length > 0;

    $("body").on("input", ".booking__service__content input[type=range]", function () {
        refreshSummaryRequest($(this), null, null);
    });
    $("body").on("change", function () {
        refreshSummaryRequest($(this), null, null);
    });

    function refreshAllInputs() {
        $.each(getInputsThatNeedRefreshing(), function (key, val) {
            refreshInputs($(val), true);
        });
    }

    $(".booking__content__list__item.list-item-variable select").trigger("change"); // Needs to be here to trigger the variable services after load!

    $(document).on("click", ".booking__products__item", function () {
        var $this = $(this);
        const dataInput = $this.attr("data-input");
        if (!dataInput) return;
        var $connectedAction = $("#" + dataInput);

        if ($this.hasClass("selected-input")) {
            $this.removeClass("selected-input");
            $connectedAction.prop("checked", false);
        } else {
            $this.addClass("selected-input");
            $connectedAction.prop("checked", true);
            $connectedAction.checked = true;
        }

        $connectedAction.trigger("change");
    });

    setupServiceCards();
    setupTimeSelector();

    if (
        typeof backendVars !== "undefined" &&
        backendVars.summaryData &&
        backendVars.summaryData.length > 0
    ) {
        refreshAllInputs();
        refreshSummary(backendVars.summaryData);
        setBookingHours(backendVars.summaryData);

        $("#empty_service").hide(function () {
            var checkboxToShow = "cleaning";

            $.each(backendVars.summaryData, function (key, val) {
                var element = val.field_name.match("([a-z]+)-input");

                if (element) {
                    checkboxToShow = element[1];
                }
            });

            var $sectionSelector = $("#" + checkboxToShow);
            $("#" + checkboxToShow + "-checkbox").prop("checked", true);

            $sectionSelector.fadeIn(500, function () {
                if (checkboxToShow !== "cleaning") {
                    $sectionSelector.find(".booking__products__item").trigger("click");
                }
            });
        });
    }

    // Cancellation Protection Checkboxes
    const cancellationProtectionCheckboxYes = $("input.cancellation-protection");
    const cancellationProtectionCheckboxNo = $("input.no-cancellation-protection");

    cancellationProtectionCheckboxYes.on("change", function () {
        const cancellationProtectionDescription = $("#cancellation_protection_desc");
        if ($(this).prop("checked")) {
            cancellationProtectionCheckboxNo.prop("checked", false).trigger("change");
            cancellationProtectionCheckboxNo
                .siblings(".no-cancellation-protection-btn")
                .removeClass("selected-input");

            if (!cancellationProtectionDescription.hasClass("active")) {
                cancellationProtectionDescription.addClass("active");
            }

            cancellationProtectionDescription
                .children(".booking__content__item__extra--yes")
                .addClass("active");
            cancellationProtectionDescription
                .children(".booking__content__item__extra--no")
                .removeClass("active");
        }
    });
    cancellationProtectionCheckboxNo.on("change", function () {
        const cancellationProtectionDescription = $("#cancellation_protection_desc");
        if ($(this).prop("checked")) {
            cancellationProtectionCheckboxYes.prop("checked", false).trigger("change");
            cancellationProtectionCheckboxNo
                .siblings(".cancellation-protection-btn")
                .removeClass("selected-input");

            if (!cancellationProtectionDescription.hasClass("active")) {
                cancellationProtectionDescription.addClass("active");
            }

            cancellationProtectionDescription
                .children(".booking__content__item__extra--no")
                .addClass("active");
            cancellationProtectionDescription
                .children(".booking__content__item__extra--yes")
                .removeClass("active");
        }
    });
    // BOOKING PAGE RELATED NECESSARY STUFF END

    $(document).on("keydown", '[name="zip-code"]', function () {
        $(this).removeClass("success");
        $(this)
            .closest(".form__item")
            .siblings(".form__item")
            .find(".btn--submit")
            .prop("disabled", true);
    });

    $(document).on("keyup", '[name="zip-code"]', function () {
        var $this = $(this);
        if ($this.val().length >= 4) {
            $this.val($this.val().substring(0, 4));
        }

        if ($this.val() < 4) {
            $this.removeClass("success");
            $this
                .closest(".form__item")
                .siblings(".form__item")
                .find(".btn--submit")
                .prop("disabled", true);
        }

        if ($this.val().length === 4) {
            $this.addClass("success");
            $this
                .closest(".form__item")
                .siblings(".form__item")
                .find(".btn--submit")
                .removeAttr("disabled");
            return;
        }
    });

    if ($(".booking__myservices__list").length > 0) {
        var zipCodeHolder = $(".booking__myservices__list .zip-holder");
        var zipCodeInput = $("#zip_code_booking");

        if (zipCodeInput.length) {
            $("body").on("click", ".zipcode-actions", function () {
                var zipChangeButton = $(this);

                if (zipChangeButton.data("type") === "change") {
                    zipChangeButton.text("Save");
                    zipChangeButton.data("type", "save");
                    zipCodeHolder.hide();

                    zipCodeInput.select2({
                        tags: true,
                        placeholder: "L - ",
                        minimumInputLength: 3,
                        ajax: {
                            type: "post",
                            url: globalVars.ajaxPaths.getZip, // The globalVars.ajaxPaths object comes from backend through ctp template
                            dataType: "json",
                            delay: 250,
                            data: function (term) {
                                return {
                                    zipCodePrefix: term.term,
                                };
                            },
                            processResults: function (data) {
                                var returnData = [];

                                $.each(data, function (key, val) {
                                    returnData.push({
                                        text: val,
                                        id: val,
                                    });
                                });

                                return {
                                    results: returnData,
                                };
                            },
                        },
                    });
                } else if (zipChangeButton.data("type") === "save") {
                    zipCodeInput.select2("destroy");
                    zipCodeHolder.show();
                    zipChangeButton.text("Change");
                    zipChangeButton.data("type", "change");

                    if (zipCodeInput.val()) {
                        zipCodeHolder.find("span").text(zipCodeInput.val());
                    }
                }
            });
        }
    }

    // SELECT2 ELEMENTS RELATED NECESSARY SECTION END

    // SLIDERS RELATED NECESSARY SECTION START
    if ($(".last-orders-tag-slider").length > 0) {
        $(".last-orders-tag-slider").slick({
            infinite: false,
            arrows: false,
            speed: 300,
            slidesToShow: 3,
            variableWidth: true,
        });
    }

    if ($(".members__slider").length > 0) {
        $(".members__slider").slick({
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 2,
            slidesToScroll: 2,
            mobileFirst: true,
            dots: true,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 955,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5,
                        dots: false,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 720,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        dots: false,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 550,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
            ],
        });
    }

    if ($("#media__slider").length > 0) {
        $("#media__slider").slick({
            arrows: false,
            dots: true,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2,
                        dots: false,
                        arrows: true,
                    },
                },
            ],
        });
    }

    if ($(".testimonials__slider").length > 0) {
        $(".testimonials__slider").slick({
            arrows: false,
            dots: true,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: false,
                        arrows: true,
                    },
                },
            ],
        });
    }

    var bookingSlider = $(".booking__steps");
    if (bookingSlider.length > 0) {
        bookingSlider.slick({
            infinite: false,
            arrows: false,
            dots: true,
            customPaging: function (slider, i) {
                return '<span class="booking__steps__indicator"></span>';
            },
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        dots: false,
                    },
                },
            ],
        });
    }

    const bundlesSlider = $("#bundles_slider");
    if (bundlesSlider.length > 0) {
        bundlesSlider.slick({
            infinite: false,
            arrows: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    },
                },
            ],
        });
    }
    // SLIDERS RELATED NECESSARY SECTION END

    // FRONTEND RELATED NECESSARY SECTION START
    var $rangeInput = $("input[type=range]");

    if ($rangeInput.length) {
        $rangeInput.each(function () {
            calculateRangeSlider($(this));
        });
    }

    function preventFormSubmitIfSomeValuesAreNotFilledIn() {
        $(document).on("submit", ".booking > form", function (e) {
            if ($("#cleaning-checkbox")[0]?.checked) {
                const $multiplier = $("#multiplier");
                if (!$multiplier.val()) {
                    $multiplier.closest(".booking__opportunity").addClass("error");
                    e.preventDefault();
                    scrollToElement($multiplier, -150);
                }
            }
            if ($("#car-washing-checkbox")[0]?.checked) {
                const selectedCategory =
                    [...$("input[name=car_category]")].find((elem) => elem.checked)?.value || null;
                if (!selectedCategory) {
                    e.preventDefault();
                    const buttonGroup = $("#car-washing .booking__opportunity");
                    buttonGroup.addClass("error");
                    scrollToElement(buttonGroup, -150);
                } else {
                    // Cakephp creates a hidden input, from which we'll later read the selected car category.
                    // We update the input's value here.
                    document.querySelector("input[type=hidden][name=car_category]").value =
                        selectedCategory;
                }
            }
        });

        $("#car-washing .booking__opportunity input").on("change", () => {
            $("#car-washing .booking__opportunity").removeClass("error");
        });
    }

    setupSummaryWindow();

    $(document).on("click touchstart", ".flash__message", function () {
        $(this)
            .addClass("fadeout")
            .on("animationend", function () {
                $(this).hide();
            }); // Same timing as the CSS animation "fadeout" for .flash__message box
    });

    // Handle opening, closing and placement of tooltips depending on resolution
    if ($(".tooltip").length > 0) {
        $("body").on("click", ".tooltip__trigger", function (e) {
            e.preventDefault();

            $(".tooltip").each(function () {
                if ($(this).hasClass("active")) {
                    $(this).removeClass("active").children(".tooltip__info").hide();
                }
            });

            if ($(window).width() <= 767) {
                $("body").addClass("no-scroll");
                $(".site__header--mobile").addClass("less-zindex");
            }

            $(this).closest(".tooltip").toggleClass("active");
            $(this).siblings(".tooltip__info").fadeToggle(250).css("display", "flex");
        });

        $(document).click(function (e) {
            $(".tooltip").each(function () {
                var isToolTipWindow = $(e.target).closest(".tooltip,.tooltip__trigger").length > 0;

                if (isToolTipWindow) {
                    return false;
                }

                $(this).removeClass("active").children(".tooltip__info").fadeOut(250);
            });
        });

        $("body").on("click", ".tooltip__info button", function (e) {
            e.preventDefault();
            var $this = $(this);

            if ($this.closest(".tooltip").hasClass("active")) {
                if ($(window).width() <= 767) {
                    $("body").removeClass("no-scroll");
                    $(".site__header--mobile").removeClass("less-zindex");
                }

                $this.closest(".tooltip").removeClass("active");
                $this.closest(".tooltip__info").fadeToggle(250).css("display", "flex");
            }
        });
    }

    function renderImgToSvg() {
        $(".svg").each(function () {
            var $img = $(this);
            var imgURL = $img.attr("src");
            var attributes = $img.prop("attributes");

            $.get(
                imgURL,
                function (data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find("svg");

                    // Remove any invalid XML tags
                    $svg = $svg.removeAttr("xmlns:a");

                    // Loop through IMG attributes and apply on SVG
                    $.each(attributes, function () {
                        $svg.attr(this.name, this.value);
                    });

                    // Replace IMG with SVG
                    $img.replaceWith($svg);
                },
                "xml",
            );
        });
    }

    function mobileNav() {
        var $mobileNav = $(".site__header__navigation--mobile");
        var $navBtn = $(".mobile__nav__btn");
        var $mobileHeader = $(".site__header--mobile");

        $("body").on("click", $navBtn, function (e) {
            var target = $(e.target);

            if (!$mobileNav.hasClass("opened") && target.hasClass("mobile__nav__btn")) {
                $mobileNav.addClass("opened");
                e.preventDefault();
                $("body").addClass("hidden").append('<span class="overlay"></span>');
                $mobileHeader.addClass("opened");
            }

            if (!target.hasClass("mobile__nav__btn")) {
                $mobileNav.removeClass("opened");
                $("body").removeClass("hidden").find(".overlay").remove();
                $mobileHeader.removeClass("opened");
            }
        });
    }

    function faqDropdown() {
        if ($(".faq__wrap").length > 0) {
            var $trigger = $(".faq__item__header");

            $trigger.on("click", function () {
                $(this).toggleClass("active").next(".faq__item__content").slideToggle(250);
            });
        }
    }

    if ($(".styled-select__text").length > 0) {
        var selectText = $(".styled-select__text");
        selectText.text(selectText.nextAll("select").find("option:selected").text());

        $("select.styled-select").on("change", function handleChange() {
            var $this = $(this);
            $this.prevAll(".styled-select__text").text($this.find(":selected").text());
        });
    }

    var $invoiceChanged = $('input[name="invoice"][type="checkbox"]');

    $invoiceChanged.change(function () {
        var doesUserWantInvoice = $invoiceChanged.is(":checked");
        $.ajax({
            type: "post",
            url: globalVars.ajaxPaths.setInvoiceInSession,
            dataType: "json",
            data: { Invoice: doesUserWantInvoice },
        }).done(function (response) {});
    });

    function isUserLoggedIn() {
        return $(".booking__payment").hasClass("logged-in") && loggedIn === true;
    }

    $("body").on(
        "change",
        "input[name=gardening], input[name=painting], input[name=cleaning-moving-out], input[name=home-organizing], input[name=pet-care], input[name=car-washing]",
        function () {
            if (!$(this).prop("checked")) {
                var inputName = $(this).attr("name") + "-input";
                $("input[name=" + inputName + "]")
                    .prop("checked", false)
                    .trigger("change");
                $('.selected-input[data-input="' + inputName + '"]').removeClass("selected-input");
            }
        },
    );

    $("body").on(
        "click",
        '.booking__service__content div[data-input="gardening-input"], .booking__service__content div[data-input="painting-input"], .booking__service__content div[data-input="moving-out-input"], .booking__service__content div[data-input="organizing-input"], .booking__service__content div[data-input="pet-care-input"], .booking__service__content div[data-input="car-washing-input"]',
        function () {
            var $this = $(this);
            var $payButton = $this.nextAll(".booking__submit").find("button");

            if (!$this.hasClass("selected-input")) {
                $payButton.prop("disabled", false);
                return;
            }

            console.log("This is happening");
            $payButton.prop("disabled", true);
        },
    );

    $(document).on("input", 'input[name="coupon_code"]', function () {
        debounce(
            () => {
                var couponCode = $(this).val();
                $(".booking__summary--step2").removeClass("has-discount");

                if (typeof backendVars.summaryData !== "undefined" && backendVars.summaryData) {
                    $.ajax({
                        type: "post",
                        url: globalVars.ajaxPaths.useCoupon,
                        dataType: "json",
                        data: {
                            coupon: couponCode,
                        },
                    }).done((response) => {
                        console.log({ response });
                        refreshLocalStateWithFreshSummary(response);
                    });
                }
            },
            500,
            "couponCodeChanged",
        );
    });

    $(document).on("input", "input[name='is_company']", function () {
        window.summaryAtom.focus((atom) => atom.loading).update(() => true);
        $.ajax({
            type: "post",
            url: "/ajax/refresh-summary",
            dataType: "json",
            data: {
                items: {
                    is_company: $(this).is(":checked"),
                },
            },
        }).done((response) => {
            window.summaryAtom.update(() => ({ loading: false, finalItems: response.finalItems }));
        });
    });

    $("input").click(function () {
        var inputFiled = $(this);
        var errorMessage = $("p[input-key=" + inputFiled.attr("name") + "]");

        if (inputFiled.css("border")) {
            inputFiled.css("border", "");
            errorMessage.hide("slow", function () {
                $target.remove();
            });
        }
    });

    $('p[class="message error"]').each(function (i, errorMessage) {
        $(errorMessage).insertAfter("body input[name=" + $(errorMessage).attr("input-key") + "]");
        $("input[name=" + $(errorMessage).attr("input-key") + "]").css("border", "1px solid red");
    });

    function manageInvoiceFields() {
        var $invoiceCheckbox = $("#invoice_address_checkbox");

        $("body").on("input", "#fname, #lname, #address, #phone", function (e) {
            if ($invoiceCheckbox.is(":checked")) {
                return false;
            }

            var $this = $(this);
            var fieldName = $this.attr("name");
            $('form input[name="invoice_' + fieldName + '"]').val($this.val());
        });

        function toggleInvoiceFields() {
            var invoiceContainer = $("body .booking__payment .different__invoice");

            console.log({ checked: $("#invoice_address_checkbox").is(":checked") });
            if ($("#invoice_address_checkbox").is(":checked")) {
                $(".paypal-overlay").show(0);
                invoiceContainer.slideDown(300);
            } else {
                invoiceContainer.slideUp(300);
            }
        }

        $("body").on("change", "#invoice_address_checkbox", toggleInvoiceFields);
        toggleInvoiceFields();
    }

    function handleMyOrdersDetails() {
        $("body").on("click", ".myorders__details__btn", function (e) {
            e.preventDefault();

            if (!$(this).hasClass("active")) {
                $(this).addClass("active").text("Show less");
            } else {
                $(this).removeClass("active").text("Details");
            }
            $(this).parent().siblings(".profile__myorders__details").slideToggle(250);
            $(this).parent().siblings(".profile__myorders__meta").toggleClass("show");
        });
    }

    function handlePaymentChangeOnclickIcon() {
        $("body").on("click", ".booking__payment__select__option", function () {
            $(this).children("input").prop("checked", true).trigger("change");
        });
    }

    $(document).on("change", '[name="zip-code"]', function () {
        var $this = $(this);
        if ($this.val() !== "" && !$this.hasClass("success")) {
            $this.addClass("success");
            $this
                .parents(".form__item")
                .siblings(".form__item")
                .find(".btn--submit")
                .removeAttr("disabled");
        }
    });

    // Scrolls to the given element. (vertical modifier is extra offset, can be positive or negative)
    function scrollToElement(element, verticalModifier) {
        if (!element || !element.length) {
            return;
        }

        verticalModifier = typeof verticalModifier !== "undefined" ? verticalModifier : 0;

        var deferred = $.Deferred(),
            elemPos = element.offset().top,
            finalOffset = elemPos + verticalModifier,
            scrollTop = $(window).scrollTop(),
            animTiming = 400;

        if (scrollTop < finalOffset) {
            animTiming = Math.floor(animTiming + (finalOffset - scrollTop) / 10);
        } else {
            animTiming = Math.floor(animTiming + (scrollTop - finalOffset) / 10);
        }

        if (elemPos > scrollTop || elemPos + element.outerHeight() < scrollTop) {
            $("html, body")
                .stop()
                .animate({ scrollTop: finalOffset < 0 ? 0 : finalOffset }, animTiming, function () {
                    deferred.resolve();
                });
        } else {
            deferred.resolve();
        }

        return deferred;
    }

    // Scroll Top scroll event & handle onClick
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        var scrollToTop = $(".scroll__top");

        if (scroll > windowHeight) {
            scrollToTop.fadeIn(200);
        } else {
            scrollToTop.fadeOut(200);
        }
    });

    var scrollToTopBtn = $("button.scroll__top__btn");

    scrollToTopBtn.on("click", function (e) {
        e.preventDefault();

        $("html, body").animate(
            {
                scrollTop: 0,
            },
            "slow",
        );
        return false;
    });

    $("body").on("submit", 'form[action="/apply-as-cleaner"]', function (e) {
        var validated = true;

        $('input[type="checkbox"]').each(function () {
            var errorMessage = $(this).closest(".form__item").find(".error-message");

            if (!$(this).prop("checked")) {
                if (errorMessage.is(":hidden")) {
                    errorMessage.show();
                    $(this).closest(".form__item").addClass("error");
                }

                validated = false;
            } else {
                errorMessage.hide();
                $(this).closest(".form__item").removeClass("error");
            }
        });

        if (!validated) {
            e.preventDefault();
        }
    });

    function refreshMultiplierValue(value) {
        $("#multiplier")[0].value = value;
        $("#multiplier").closest(".booking__opportunity").removeClass("error");
        refreshAllInputs();
    }

    const multiplierQuery = $('input[name="multiplier"][type="hidden"]');
    if (multiplierQuery[0]) {
        multiplierQuery[0].id = "multiplier";
        $('input[name="multiplier"][type="radio"]').on("change", (e) => {
            refreshMultiplierValue(e.target.value);
        });
        const selectedRadioButton = $('input[name="multiplier"][type="radio"][checked]').get(0);
        if (selectedRadioButton) {
            refreshMultiplierValue(selectedRadioButton.value);
        }
    }

    function getServicesIncludedInMovingOut() {
        return $('*[data-input-is-included-in-moving-out="1"]');
    }

    function onMovingOutSelected() {
        const wrappers = getServicesIncludedInMovingOut();
        for (const wrapper of wrappers) {
            if (!$(wrapper).hasClass("selected-input")) {
                wrapper.click();
            }
        }
        setTimeout(() => {
            refreshServiceCardPrices(true);
        }, 0);
    }

    function onMovingOutDeSelected() {
        const wrappers = getServicesIncludedInMovingOut();
        for (const wrapper of wrappers) {
            if (
                $(wrapper).hasClass("selected-input") &&
                !$(wrapper).attr("data-manually-checked")
            ) {
                wrapper.click();
            }
        }
        setTimeout(() => {
            refreshServiceCardPrices(false);
        }, 0);
    }

    $('*[for="multiplier-moving_out"]').on("click", () => {
        onMovingOutSelected();
    });
    $('*[for="multiplier-weekly_or_monthly"]').on("click", () => {
        onMovingOutDeSelected();
    });
    $('*[for="multiplier-seasonal_or_annual"]').on("click", () => {
        onMovingOutDeSelected();
    });

    function handleCleaningEquipment($cleaningEquipmentBoxes) {
        var checkedCount = 0;
        var $cleaningEquipmentWarning = $("#warning_equipment_cleaning");

        $.each($cleaningEquipmentBoxes, function (key, val) {
            if ($(val).prop("checked")) {
                checkedCount++;
            }
        });

        if (checkedCount > 0) {
            $cleaningEquipmentWarning.removeClass("show");
            return;
        }

        $cleaningEquipmentWarning.addClass("show");
    }

    function onChangeCleaningEquipment() {
        var $cleaningEquipmentBoxes = $(
            'input[name="equipment-cleaning-17"][type="checkbox"], input[name="equipment-cleaning-18"][type="checkbox"], input[name="equipment-cleaning-19"][type="checkbox"]',
        );
        handleCleaningEquipment($cleaningEquipmentBoxes);

        $cleaningEquipmentBoxes.change(function () {
            handleCleaningEquipment($cleaningEquipmentBoxes);
        });
    }

    function loadHighresImages() {
        $("picture.img-low-res").each(function (key, val) {
            var $pictureElement = $(val);
            var imageSrc = $pictureElement.find("img").attr("src");
            var $pictureElementHighRes = $pictureElement.clone();

            $pictureElementHighRes.find("source").attr("srcset", (i, value) => {
                if (isMobile) {
                    return value.replace("-low-res", "-mobile-res");
                } else {
                    return value.replace("-low-res", "");
                }
            });

            if (isMobile) {
                $pictureElementHighRes
                    .find("img")
                    .attr("src", imageSrc.replace("-low-res", "-mobile-res"));
            } else {
                $pictureElementHighRes.find("img").attr("src", imageSrc.replace("-low-res", ""));
            }

            $pictureElementHighRes.removeClass("img-low-res").addClass("img-high-res");
            $pictureElementHighRes.insertAfter($pictureElement);

            $pictureElementHighRes.find("img").on("load", function () {
                $pictureElement.fadeOut(2800);
                $pictureElementHighRes.fadeIn(1000);
            });
        });
    }

    if (!(hash === null) && !(hash === "")) {
        focusZipSelector();

        if (hash.indexOf("order-")) {
            $(hash).addClass("highlight");
            scrollToElement($(hash), -50);
        }
    }

    $(".free-offer").on("click", function (e) {
        e.preventDefault();
        handleTogglePopup(true);
    });

    function handleTogglePopup(forceOpen = false) {
        const $popupWindow = $(".popup");

        if (
            ($popupWindow.length &&
                !$popupWindow.hasClass("bookacleaner") &&
                $popupWindow.attr("id") !== "booking_error_popup" &&
                $popupWindow.attr("id") !== "bundles_booknow_popup") ||
            forceOpen
        ) {
            $popupWindow.addClass("show");
        }

        $(".popup__close__btn").on("click", function (e) {
            e.preventDefault();
            const btn = $(this);
            btn.parents(".popup").removeClass("show");
        });
    }

    function handleAccordion() {
        const showMoreBtn = $(".accordion__trigger--more");
        const showLessBtn = $(".accordion__trigger--less");

        showMoreBtn.on("click", function () {
            const accordionId = $(this).attr("accordion-id");

            $("#" + accordionId).fadeIn(150);
            showMoreBtn.hide();
            showLessBtn.show();
        });

        showLessBtn.on("click", function () {
            const accordionId = $(this).attr("accordion-id");

            $("#" + accordionId).fadeOut(150);
            showMoreBtn.show();
            showLessBtn.hide();
        });
    }

    handleAccordion();

    // FRONTEND RELATED NECESSARY SECTION END

    // PROFILE RELATED STUFF START
    if ($(".profile__myorders").find(".profile__history__item").length) {
        $("body").on("click", ".load-more", function (e) {
            e.preventDefault();
            var $this = $(this);
            var currentPage = $this.data("page");

            $.ajax({
                type: "post",
                url: globalVars.ajaxPaths.loadOrders,
                data: {
                    page: currentPage + 1,
                },
            }).done(function (response) {
                if (response) {
                    $this.data("page", currentPage + 1);
                    $(response).insertBefore(".load-more");
                } else {
                    $this.fadeOut(100);
                }
            });
        });
    }

    // PROFILE RELATED STUFF END

    function validateRecaptcha() {
        $(".g-recaptcha")
            .closest("form")
            .on("submit", function (e) {
                if (!grecaptcha) {
                    e.preventDefault();
                    alert("Please wait for the captcha to load!");
                }

                var captchaResponse = grecaptcha.getResponse();

                if (!captchaResponse.length) {
                    e.preventDefault();
                }
            });
    }

    function focusZipSelector() {
        $('[name="zip-code"]').focus();
        $('[name="zip-code"]').select2("open");
    }

    function preventFormDoubleSubmit() {
        for (const button of $("form button[type=submit]")) {
            $(button).on("click", () => {
                button.style.pointerEvents = "none";
                setTimeout(() => {
                    button.style.pointerEvents = "initial";
                }, 5000);
            });
        }
    }

    function handleInputFileChanges() {
        const fileInput = $('input[type="file"]');

        fileInput.on("change", function () {
            const selectedFilesNames = $(".file-upload__names");

            let inputFileNames = [];
            for (let i = 0; i < $(this).get(0).files.length; ++i) {
                inputFileNames.push($(this).get(0).files[i].name);
            }

            if (selectedFilesNames.children()) {
                selectedFilesNames.children().remove();
            }

            selectedFilesNames.append(
                ...inputFileNames.map(
                    (name) =>
                        `<span class="file-upload__names__item">
                            ${name}
                         </span>`,
                ),
            );

            if (inputFileNames.length) {
                fileInput[0].checked = true;
            } else {
                fileInput[0].checked = false;
            }
        });
    }

    function shouldShowBookingTimeWarningPopup() {
        const maxEndTimeInMinutes = 18 * 60;
        const bookingRecommendedHours = parseInt($("input#recommended_hours").val());
        if (!bookingRecommendedHours) {
            return false;
        }
        const extraHours =
            parseInt($("#booking__extra__hours__text").get(0)?.textContent || "0") || 0;

        const bookingStartTimeSelect = $(".styled-select select[name=cleaning-start_time]");
        const currentValue = bookingStartTimeSelect.val();
        if (!currentValue) {
            return false;
        }
        const hours = Number(currentValue.split(":")[0]);
        const mins = Number(currentValue.split(":")[1]);
        const selectedEndTimeInMinutes = (hours + bookingRecommendedHours + extraHours) * 60 + mins;
        return selectedEndTimeInMinutes > maxEndTimeInMinutes;
    }

    let wasBookingTimeWarningShown = false;

    function setupBookingTimesErrorPopup() {
        $("button[type=submit]").on("pointerdown", function (e) {
            const button = $(this);
            if (!wasBookingTimeWarningShown && shouldShowBookingTimeWarningPopup()) {
                e.preventDefault();
                const bookingStartTimeErrorPopup = $("#booking_error_popup");
                bookingStartTimeErrorPopup.addClass("show");
                wasBookingTimeWarningShown = true;
                $(".popup__close").on("click", () => {
                    button.click();
                });
            }
        });
    }

    function handleFlipCard() {
        const flipCard = $(".flip-card__front");
        const flipCardCloseBtn = $(".flip-card__close");

        flipCard.on("click", function () {
            $(this).parents(".flip-card").addClass("active");
        });
        flipCardCloseBtn.on("click", function () {
            $(this).parents(".flip-card").removeClass("active");
        });
    }

    function openBundlePopup() {
        const bundlePopupCta = $(".bundles__item__cta");

        bundlePopupCta.on("click", function (e) {
            e.preventDefault();
            const popupId = $(this).attr("href");

            $("" + popupId).addClass("show");
        });
    }

    function deselectFreeQuoteWhenAppropriate() {
        $("body").on("change", () => {
            if (
                $("#moving-out_option-moving_out_without_quote")?.prop("checked") ||
                $("#multiplier-seasonal_or_annual")?.prop("checked") ||
                $("#multiplier-weekly_or_monthly")?.prop("checked")
            ) {
                const input = $("input[name^=cleaning-][data-free-quote]");
                if (input.prop("checked")) {
                    input.click();
                }
            }
        });
    }

    function setupDeselectableRadioButtons() {
        $("input[type=radio][data-deselectable='1']").on("click", function (event) {
            const element = this;
            if (element.getAttribute("data-is-selected") === "1") {
                element.checked = false;
                element.setAttribute("data-is-selected", 0);
            } else {
                element.checked = true;
                element.setAttribute("data-is-selected", 1);
            }
            $(element).trigger("change");
        });
    }

    function setupSaveingOfPaymentDetails() {
        const form = document.querySelector("#save-payment-details-form");
        if (form) {
            form.addEventListener("submit", async function (event) {
                event.preventDefault();
                if (!isUserLoggedIn()) {
                    await createCustomer("borgun");
                }
                const cleaningId = this.action.match(/\d+$/)[0];
                const clientSecret = await fetch(this.action, { method: "POST" }).then((response) =>
                    response.text(),
                );
                const elements = stripe.elements({ clientSecret });
                const paymentElement = elements.create("payment");
                paymentElement.mount("#payment-element");
                const paymentForm = document.querySelector("#payment-form");
                paymentForm.classList.add("not-hidden");
                $("body").trigger("change");

                paymentForm.addEventListener("submit", async function (event) {
                    event.preventDefault();

                    const { error } = await stripe.confirmPayment({
                        //`Elements` instance that was used to create the Payment Element
                        elements,
                        confirmParams: {
                            return_url:
                                location.protocol +
                                "//" +
                                location.host +
                                "/cleanings/success/" +
                                cleaningId,
                        },
                    });

                    if (error) {
                        // This point will only be reached if there is an immediate error when
                        // confirming the payment. Show error to your customer (for example, payment
                        // details incomplete)
                        const messageContainer = document.querySelector("#error-message");
                        messageContainer.textContent = error.message;
                    } else {
                        // Your customer will be redirected to your `return_url`. For some payment
                        // methods like iDEAL, your customer will be redirected to an intermediate
                        // site first to authorize the payment, then redirected to the `return_url`.
                    }
                });
            });
        }
    }

    function setupAddingBundlesToCleaning() {
        $(".bundles__slider__item[data-bundle-id]").on("click", function () {
            fetch("/ajax/addBundleToCleaning/" + this.getAttribute("data-bundle-id"), {
                method: "POST",
                redirect: "follow",
            }).then(() => {
                window.location.replace("/booking");
            });
        });
    }

    loadHighresImages();
    manageInvoiceFields();
    renderImgToSvg();
    mobileNav();
    faqDropdown();
    handleMyOrdersDetails();
    handlePaymentChangeOnclickIcon();
    onChangeCleaningEquipment();
    handleTogglePopup();
    validateRecaptcha();
    preventFormSubmitIfSomeValuesAreNotFilledIn();
    preventFormDoubleSubmit();
    handleInputFileChanges();
    setupBookingTimesErrorPopup();
    handleValidations();
    handleFlipCard();
    openBundlePopup();
    setupConditionalRendering();
    setupBundlePopup();
    deselectFreeQuoteWhenAppropriate();
    setupDeselectableRadioButtons();
    setupMultipartForm();
    setupSaveingOfPaymentDetails();
    setupAddingBundlesToCleaning();
    window.ready = true;
})(jQuery);
